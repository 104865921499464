<template>
  <div class="forget-password" style="margin: 50px 20px 10px 20px; max-width: 500px;">
    <div class="name" style="font-size: 23px; margin-bottom: 30px;">
      <!-- 找回密码 -->
      Recuperar contraseña
    </div>

    <el-form
      :model="ruleForm"
      label-position="top"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <!-- 邮件 -->
      <el-form-item label="Correo electrónico" prop="mail">
        <el-input type="text" autocomplete="off" v-model="ruleForm.mail"></el-input>
      </el-form-item>
      <!-- 验证码 -->

      <!-- 获取验证码 -->
      <el-form-item label="Código" prop="code">
        <el-input type="text" style="width: 240px; margin-right: 20px" v-model="ruleForm.code"></el-input>
        <el-button @click="getCode">{{ codeTimeout > 0 ? `(${codeTimeout} s)` : `Obtener el código` }}</el-button>
      </el-form-item>
      <!-- 密码 -->
      <el-form-item label="Nueva contraseña " prop="pass">
        <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <!-- 确认密码 -->
      <el-form-item label="Confirma la nueva contraseña" prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>

      <!-- 提交 -->
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">Enviar</el-button>
        <!-- 重置 -->
        <el-button @click="resetForm('ruleForm')">Reiniciar</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getMailCode, setPwd } from '@/api'

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        // 请输入密码
        callback(new Error('Nueva contraseña'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        // 请再次输入密码
        callback(new Error('Confirma la nueva contraseña'))
      } else if (value !== this.ruleForm.pass) {
        // 两次输入密码不一致!
        callback(new Error('Dos contraseñas son inconsistentes'))
      } else {
        callback()
      }
    }
    return {
      codeTimeout: 0,
      ruleForm: {
        pass: '',
        checkPass: '',
        mail: '',
        code: ''
      },
      rules: {
        // 请输入验证码
        code: [{ required: true, message: 'Código', trigger: 'blur' }],
        pass: [
        // 请输入密码
          { required: true, message: 'Nueva contraseña', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
        mail: [
        // 请输入邮箱地址
          { required: true, message: 'Correo electrónico', trigger: 'blur' },
          // 请输入正确的邮箱地址
          {
            type: 'email',
            message: 'Por favor confirme el correo electrónico',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    getCode() {
      const params = { email: this.ruleForm.mail }
      // console.log(this.codeTimer, params.email)
      if (this.codeTimer > 0 || !params.email) {
        return
      }
      getMailCode(params)
        .then(res => {
          // console.log(res.data.status)
          if ('SUCCESSS' === res.data.status) {
            this.codeTimeout = 6
            this.codeTimer = setInterval(() => {
              this.codeTimeout--
              if (this.codeTimeout < 1) {
                clearInterval(this.codeTimer)
                this.codeTimeout = 0
              }
            }, 1000)
          } else {
            this.$message.error(res.data.msg || 'error')
            // console.log('err', err)
          }
        })
        .catch(() => {
          this.$message.error('error')
        })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // alert('submit!')
          // console.log(params)
          const { mail: email, pass: pwd, checkPass: newPwd, code } = this.ruleForm
          const params = {
            email,
            pwd,
            newPwd,
            code
          }

          setPwd(params).then(res => {
            if (res.data.status === 'fail') {
              this.$message.error(res.data.msg)
            } else {
              this.$router.replace('/')
              this.$message.success(res.data.msg || 'success')
            }
          })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
